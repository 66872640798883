import VideoElement from "./VideoElement";
import ImageElement from "./ImageElement";
import TextElement from "./TextElement";
import DateElement from "./DateElement";
import WeatherElement from "./WeatherElement";
import QrcodeElement from "./QrcodeElement";
import CountdownElement from "./CountdownElement";
import StreamingElement from "./StreamingElement";
import LocationElement from "./LocationElement";
import SportElement from "./SportElement";

import { isRotated, interpolateOpacity } from "../helpers";

function getStyle({ currentTime, player, element, playlist = false }) {
  const { timeline, style = {} } = element;
  const { top, left, height, width, zIndex } = style;

  const rotated = isRotated() || (player && player.rotate);

  return {
    top: top,
    left: left,
    height: height,
    width: width,
    zIndex: zIndex,
    opacity: playlist ? interpolateOpacity(currentTime, timeline) : 1,
    ...(rotated && { transform: `translateZ(${zIndex}em)` }),
  };
}

function Element({
  element,
  playing,
  syncPlayback,
  player,
  currentTime,
  location,
  lang
}) {
  function renderElementType(style) {
    switch (element.type) {
      case "text":
        return <TextElement element={element} />;
      case "image":
        return (
          <ImageElement
            element={element}
            playing={playing}
            visible={style.opacity > 0}
          />
        );
      case "video":
        return (
          <VideoElement
            element={element}
            player={player}
            visible={style.opacity > 0}
            playing={playing}
            syncPlayback={syncPlayback}
          />
        );
      case "qrcode":
        return <QrcodeElement element={element} visible={style.opacity > 0} />;
      case "date":
        return <DateElement element={element} lang={lang} />;
      case "weather":
        return (
          <WeatherElement
            element={element}
            player={player}
            location={location}
          />
        );
      case "countdown":
        return <CountdownElement element={element} />;
      case "streaming":
        return (
          <StreamingElement element={element} visible={style.opacity > 0} />
        );
      case "location":
        return (
          <LocationElement
            element={element}
            player={player}
            location={location}
          />
        )
      case "transit":
        return (
          <LocationElement
            element={element}
            player={player}
            location={location}
          />
        )
      case "sport":
        return(
          <SportElement element={element}></SportElement>
        )
      default:
        return null;
    }
  }

  const style = getStyle({ currentTime, player, element });

  return (
    <div className="element" style={style}>
      {renderElementType(style)}
    </div>
  );
}

export default Element;
